import React from 'react';
import inz from '../static/LOGOICON.png';
import besvg from '../static/B.png';
import instasvg from '../static/ig.png';
import './NavBar.css';
                
const NavigationBar = () => {
    return (
        <nav className="Nav-navbar">
            <div className='Nav-logo'>
                <a href='/' className='Nav-a'>
                    <img src={inz} className='Nav-logo-img' alt='主頁'/>
                </a>
            </div>
            <div className='Nav-navclick'>
                <a href='/works' className='Nav-a'>
                    <span>
                    INDEX
                    </span>
                </a>
                <a href='/info' className='Nav-a nav-i'>
                    <span>
                    INFO
                    </span>
                </a>
                <a href='/contact' className='Nav-a'>
                    <span>
                    CONTACT
                    </span>
                </a>
            </div>
            <div className='Nav-svg'>
                    <a href='https://www.behance.net/yinzhanhuang' target='_blank' rel='noreferrer' className='Nav-a nav-be'>
                        <img className='Nav-svg-be' src={besvg} alt='behance'/>
                        {/* <span>behance</span> */}
                    </a>
                    <a href='https://www.instagram.com/inzstudio_/' target='_blank' rel='noreferrer' className='Nav-a nav-ig'>
                        <img className='Nav-svg-ig' src={instasvg} alt='instagram'/>
                        {/* <span>instagram</span> */}
                    </a>
            </div>
        </nav>
    );
};

export default NavigationBar;
                