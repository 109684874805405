import React, { useEffect, useState } from 'react';
import { Table, Modal, Input, Button, message } from 'antd';
import axios from 'axios';
import './ExperienceInfoDM.css';

const ExperienceInfoDM = () => {
  const [data, setData] = useState([]);
  const [editingExperienceInfo, setEditingExperienceInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [originalSn, setOriginalSn] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/experience', {
        withCredentials: true
      });
      const sortedData = response.data.sort((a, b) => a.sn - b.sn);
      setData(sortedData);
    } catch (error) {
      message.error('無法從後端加載數據');
    }
  };

  const showModal = (experienceInfo) => {
    if (experienceInfo) {
      setEditingExperienceInfo(experienceInfo);
      setOriginalSn(experienceInfo.sn); // 设置原始的 SN
      setIsEditing(true);
    } else {
      setEditingExperienceInfo({ experience_en: '', experience_zh: '' });
      setOriginalSn(null); // 重置 originalSn
      setIsEditing(false);
    }
    setIsModalVisible(true);
  };

  const checkSnExists = async (sn) => {
    if (!/^\d+$/.test(sn)) {
      message.error('SN 必須為純數字');
      return false;
    }
    const response = await axios.get(`/api/experience/check-sn?sn=${sn}`, {
      withCredentials: true,
    });
    return response.data; // 返回 true 或 false 表示 SN 是否存在
  };

  const handleOk = async () => {
    try {
      let experienceInfoToSave = { ...editingExperienceInfo };
  
      // 在保存之前，先检查 SN 的唯一性
      const snExists = await checkSnExists(experienceInfoToSave.sn);
      if (!snExists && experienceInfoToSave.sn !== originalSn) {
        message.error('SN 與現有數據重複，請修改 SN');
        return; // 如果 SN 已存在，并且不是当前正在编辑的原始 SN，则阻止保存
      }
      if (isEditing) {
        await axios.put(`/api/experience/${originalSn}`, experienceInfoToSave, {
          withCredentials: true,
        });
      } else {
        await axios.post('/api/experience/create', experienceInfoToSave, {
          withCredentials: true,
        });
      }

      fetchData();
      message.success(isEditing ? 'ExperienceInfo 更新成功' : 'ExperienceInfo 保存成功');
    } catch (error) {
      console.log(error);
      message.error(isEditing ? 'ExperienceInfo 更新失败，请稍后重试' : 'ExperienceInfo 保存失败，请稍后重试');
    }
    setIsModalVisible(false);
    setIsEditing(false);
  };

  //刪除路由
  const handleDelete = async (sn) => {
    try {
      await axios.delete(`/api/experience/${sn}`, {
        withCredentials: true
      });
      fetchData();
      message.success('ExperienceInfo刪除成功');
    } catch (error) {
      message.error('ExperienceInfo刪除失敗，請稍後重試');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e, key) => {
    setEditingExperienceInfo({ ...editingExperienceInfo, [key]: e.target.value });
  };

  const modalFooter = (
    <div>
      <Button key="back" onClick={handleCancel} className='experienceinfobtn'>
        取消
      </Button>
      <Button key="submit" type="primary" onClick={handleOk} className='experienceinfobtn submit-btn'>
        確定
      </Button>
    </div>
  );

  const columns = [
    {
      title: '編號',
      dataIndex: 'sn',
      key: 'sn',
      width: "4%",
    },
    {
      title: 'experience 英文',
      dataIndex: 'experience_en',
      key: 'experience_en',
      width: "45%",
    },
    {
      title: 'experience 中文',
      dataIndex: 'experience_zh',
      key: 'experience_zh',
      width: "45%",
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width:"6%",
      render: (_, record) => (
        <div className='btngroup'>
          <Button className='experienceinfodmcbtn' onClick={() => showModal(record)}>編輯</Button>
          <Button className='experienceinfodmcbtn delete-button' onClick={() => handleDelete(record.sn)}>刪除</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" className='experienceinfodmbtn experienceinfomainbtn addworks-addbtn' onClick={() => showModal()}>新增ExperienceInfo</Button>
      <Table
        className='experienceinfodmtable'
        dataSource={data}
        columns={columns}
        rowKey="sn"
        pagination={false}
        sortBy="sn"
      />
      <Modal
        title="編輯ExperienceInfo"
        open={isModalVisible}
        footer={modalFooter}
        onCancel={handleCancel}
      >
        <Input className='textbox' value={editingExperienceInfo.sn} onChange={(e) => handleInputChange(e, 'sn')} placeholder="編號" />
        <Input className='textbox' value={editingExperienceInfo.experience_en} onChange={(e) => handleInputChange(e, 'experience_en')} placeholder="experience 英文" />
        <Input className='textbox' value={editingExperienceInfo.experience_zh} onChange={(e) => handleInputChange(e, 'experience_zh')} placeholder="experience 中文" />
      </Modal>
    </>
  );
};

export default ExperienceInfoDM;