import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // 導入 useNavigate
import './login.css';
import NavigationBar from '../nav/NavBar';

function LoginPage() {
    const navigate = useNavigate();
    const [username, setUsername] = useState(''); // 定義 username 狀態
    const [password, setPassword] = useState(''); // 定義 password 狀態

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/login', { username, password });
            const { accessToken } = response.data;
            document.cookie = `yinz=${accessToken}; path=/;max-age=3600`;
            navigate('/admin'); // 導航到受保護的路由
        } catch (error) {
            console.error('登入失敗:', error);
            // 處理錯誤

        }
    };
    const validateCookie = async () => {
        const response = await fetch('/api/auth/validateToken', {
            method: 'POST',
            credentials: 'include'  // 确保发送 Cookie
        });
        const data = await response.json();
        if (response.ok) {
            setUsername(data.username);
            navigate('/admin');
        }
    };
    validateCookie();

    return (
        <main>
        <div className='nav-wrapper'>
            <NavigationBar />
        </div>
        <div className="login-border-wrapper">
            <div className='login-login-form'>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={username}
                        className='login-input'
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                    />
                    <input
                        type="password"
                        className='login-input'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    <button type="submit" className='login-button'>Login</button>
                </form>
            </div>
        </div>
        </main>
    );
}

export default LoginPage;
