import React, { useEffect, useState } from 'react';
import { Table, Modal, Input, Button, message } from 'antd';
import axios from 'axios';
import './ExhibitionInfoDM.css';

const ExhibitionInfoDM = () => {
  const [data, setData] = useState([]);
  const [editingExhibitionInfo, setEditingExhibitionInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [originalSn, setOriginalSn] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/exhibition', {
        withCredentials: true
      });
      const sortedData = response.data.sort((a, b) => a.sn - b.sn);
      setData(sortedData);
    } catch (error) {
      message.error('無法從後端加載數據');
    }
  };

  const showModal = (exhibitionInfo) => {
    if (exhibitionInfo) {
      setEditingExhibitionInfo(exhibitionInfo);
      setOriginalSn(exhibitionInfo.sn); // 设置原始的 SN
      setIsEditing(true);
    } else {
      setEditingExhibitionInfo({ exhibition_en: '', exhibition_zh: '' });
      setOriginalSn(null); // 重置 originalSn
      setIsEditing(false);
    }
    setIsModalVisible(true);
  };

  const checkSnExists = async (sn) => {
    if (!/^\d+$/.test(sn)) {
      message.error('SN 必須為純數字');
      return false;
    }
    const response = await axios.get(`/api/exhibition/check-sn?sn=${sn}`, {
      withCredentials: true,
    });
    return response.data; // 返回 true 或 false 表示 SN 是否存在
  };
  
  const handleOk = async () => {
    try {
      let exhibitionInfoToSave = { ...editingExhibitionInfo };
  
      // 在保存之前，先检查 SN 的唯一性
      const snExists = await checkSnExists(exhibitionInfoToSave.sn);
      if (!snExists && exhibitionInfoToSave.sn !== originalSn) {
        message.error('SN 與現有數據重複，請修改 SN');
        return; // 如果 SN 已存在，并且不是当前正在编辑的原始 SN，则阻止保存
      }

      if (isEditing) {
        await axios.put(`/api/exhibition/${originalSn}`, exhibitionInfoToSave, {
          withCredentials: true,
        });
      } else {
        await axios.post('/api/exhibition/create', exhibitionInfoToSave, {
          withCredentials: true,
        });
      }

      fetchData();
      message.success(isEditing ? 'ExhibitionInfo 更新成功' : 'ExhibitionInfo 保存成功');
    } catch (error) {
      console.log(error);
      message.error(isEditing ? 'ExhibitionInfo 更新失败，请稍后重试' : 'ExhibitionInfo 保存失败，请稍后重试');
    }
    setIsModalVisible(false);
    setIsEditing(false);
  };

  //刪除路由
  const handleDelete = async (sn) => {
    try {
      await axios.delete(`/api/exhibition/${sn}`, {
        withCredentials: true
      });
      fetchData();
      message.success('ExhibitionInfo刪除成功');
    } catch (error) {
      message.error('ExhibitionInfo刪除失敗，請稍後重試');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e, key) => {
    setEditingExhibitionInfo({ ...editingExhibitionInfo, [key]: e.target.value });
  };

  const modalFooter = (
    <div>
      <Button key="back" onClick={handleCancel} className='exhibitioninfobtn'>
        取消
      </Button>
      <Button key="submit" type="primary" onClick={handleOk} className='exhibitioninfobtn submit-btn'>
        確定
      </Button>
    </div>
  );

  const columns = [
    {
      title: '編號',
      dataIndex: 'sn',
      key: 'sn',
      width: "4%",
    },
    {
      title: 'exhibition 英文',
      dataIndex: 'exhibition_en',
      key: 'exhibition_en',
      width: "45%",
    },
    {
      title: 'exhibition 中文',
      dataIndex: 'exhibition_zh',
      key: 'exhibition_zh',
      width: "45%",
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: "6%",
      render: (_, record) => (
        <div className='btngroup'>
          <Button className='exhibitioninfodmcbtn' onClick={() => showModal(record)}>編輯</Button>
          <Button className='exhibitioninfodmcbtn delete-button' onClick={() => handleDelete(record.sn)}>刪除</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" className='exhibitioninfodmbtn exhibitioninfomainbtn addworks-addbtn' onClick={() => showModal()}>新增ExhibitionInfo</Button>
      <Table
        className='exhibitioninfodmtable'
        dataSource={data}
        columns={columns}
        rowKey="sn"
        pagination={false}
        sortBy="sn"
      />
      <Modal
        title="編輯ExhibitionInfo"
        open={isModalVisible}
        footer={modalFooter}
        onCancel={handleCancel}
      >
        <Input className='textbox' value={editingExhibitionInfo.sn} onChange={(e) => handleInputChange(e, 'sn')} placeholder="編號" />
        <Input className='textbox' value={editingExhibitionInfo.exhibition_en} onChange={(e) => handleInputChange(e, 'exhibition_en')} placeholder="exhibition 英文" />
        <Input className='textbox' value={editingExhibitionInfo.exhibition_zh} onChange={(e) => handleInputChange(e, 'exhibition_zh')} placeholder="exhibition 中文" />
      </Modal>
    </>
  );
};

export default ExhibitionInfoDM;