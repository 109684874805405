import React, {useEffect, useState} from 'react';
import './WorksOverview.css';
import NavigationBar from '../nav/NavBar';
// import CopyrightNotice from '../corpyrignt/CopyrightNotice';

const WorksOverview = () => {
    const [works, setWorks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [displayedWorks, setDisplayedWorks] = useState([]);
    // const [isFilterOpen, setIsFilterOpen] = useState(true);

    useEffect(() => {
        fetch('/api/works')
            .then(response => {
                if(response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(data => {
                const worksWithLoadingState = data.map(work => ({
                    ...work,
                    isLoaded: false,
                    categories: work.categories && work.categories.length > 0 ? work.categories : ["未分類"]
                }));
                setWorks(worksWithLoadingState);
    
                worksWithLoadingState.forEach(work => {
                    preloadImage(work, () => {
                        setWorks(currentWorks => currentWorks.map(w => w.sn === work.sn ? { ...w, isLoaded: true } : w));
                    });
                });
    
                const allCategories = [...new Set(data.flatMap(work => work.categories && work.categories.length > 0 ? work.categories : []))];
                // 檢查是否有未分類的作品，如果沒有則不添加"未分類"
                const hasUncategorized = data.some(work => !(work.categories && work.categories.length > 0));
                if (hasUncategorized && !allCategories.includes("未分類")) {
                    allCategories.push("未分類");
                }
                setCategories(allCategories);
    
                const allYears = [...new Set(data.map(work => new Date(work.update_Time).getFullYear()))];
                setYears(allYears);
            })
            .catch(error => console.error('Error fetching data: ', error));
    }, []);
    

    useEffect(() => {
        const filteredWorks = works.map(work => ({
            ...work,
            categories: work.categories === null ? ["未分類"] : work.categories
        })).filter(work =>
            (selectedCategories.length === 0 || selectedCategories.every(category => work.categories.includes(category))) &&
            (selectedYears.length === 0 || selectedYears.includes(new Date(work.update_Time).getFullYear()))
        );
        setDisplayedWorks(filteredWorks);
    }, [selectedCategories, selectedYears, works]);

    const preloadImage = (work) => {
        const img = new Image();
        img.onload = () => {
            setWorks(currentWorks => currentWorks.map(w =>
                w.sn === work.sn ? { ...w, isLoaded: true, loadedSrc: img.src } : w));
        };
        img.src = work.link;
    };

    const handleCategoryClick = (category) => {
        setSelectedCategories(prevSelectedCategories => {
            if (prevSelectedCategories.includes(category)) {
                return prevSelectedCategories.filter(c => c !== category);
            } else {
                const newSelectedCategories = [...prevSelectedCategories, category];
                const availableYears = getAvailableYears(newSelectedCategories);
                setSelectedYears(prevSelectedYears => prevSelectedYears.filter(year => availableYears.includes(year)));
                return newSelectedCategories;
            }
        });
    };

    const handleYearClick = (year) => {
        setSelectedYears(prevSelectedYears => {
            if (prevSelectedYears.includes(year)) {
                return prevSelectedYears.filter(y => y !== year);
            } else {
                return [...prevSelectedYears, year];
            }
        });
    };

    const getAvailableYears = (selectedCategories) => {
        if (selectedCategories.length === 0) {
            return years;
        }
    
        const filteredWorks = works.filter(work =>
            selectedCategories.some(category => work.categories.includes(category))
        );
    
        return [...new Set(filteredWorks.map(work => new Date(work.update_Time).getFullYear()))];
    };
    const formatYear = (year) => {
        const yearString = year.toString();
        return yearString.replace(/^20/, '²⁰');
    };
    const getWorkDetails = (sn) => {
        const work = works.find(work => work.sn === sn);
        // 格式化sn，不足兩位數的前面加0
        const formattedSn = sn.padStart(2, '0');
        return work && work.description ? { sn: formattedSn, description: work.description } : null;
    };

    return (
        <main className='WorksOverview-main'>
            <div className='WorksOverview-container'>
                <NavigationBar />
                <div className="logo-wall-info">
                    <div className="logo-wrapper first">
                        <div className="logo">
                            <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Copyright © 2021-2024 inz studio. All rights reserved.
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>
                    </div>
                    <div className="logo-wrapper second">
                        <div className="logo">
                            <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Copyright © 2021-2024 inz studio. All rights reserved.
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>
                    </div>
                    </div>
                <div className="WorksOverview-main-content">
                        <div className="WorksOverview-main-filter">
                            <div className="WorksOverview-main-filter-categories WorksOverview-filter">
                                {categories.filter(category => category !== null).map(category => (
                                    <div
                                        key={category}
                                        className={`WorksOverview-main-filter-category ${selectedCategories.includes(category) ? 'selected' : ''}`}
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        <span style={{ textDecoration: selectedCategories.includes(category) ? 'line-through' : 'none' }}>
                                            &nbsp;&nbsp;&nbsp;{"(  " + category +  '  )'}&nbsp;
                                        </span>
                                    </div>
                                ))}
                            </div>
                                <div className="WorksOverview-main-filter-years WorksOverview-filter">
                                    {years.map(year => {
                                        const isDisabled = !getAvailableYears(selectedCategories).includes(year);
                                        const formattedYear = formatYear(year);
                                        return (
                                            <div
                                                key={year}
                                                className={`WorksOverview-main-filter-year ${selectedYears.includes(year) ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
                                                onClick={() => !isDisabled && handleYearClick(year)}
                                            >
                                                <span style={{ textDecoration: selectedYears.includes(year) ? 'line-through' : 'none' }}>
                                                    &nbsp;&nbsp;&nbsp;(  {formattedYear +  '  )'} &nbsp;
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                    <hr className='WorksOverview-title-hr'></hr>
                    <div className="WorksOverview-title">
                        {displayedWorks.map(work => {
                            const workDetails = getWorkDetails(work.sn);
                            if (workDetails) {
                                const { sn, description } = workDetails;
                                return (
                                    <a key={sn} href={`/work/${work.title}`} className='WorksOverview-title-link'>
                                        <div key={sn} className='WorksOverview-title-in'>
                                            <span className='wo-span wo-n'>{sn + '.'}</span>
                                            <span className='wo-span wo-w'>{description}</span>
                                        </div>
                                    </a>    
                                );
                            }
                            return null;
                        })}
                    </div>
                    <div className="WorksOverview-main-side">
                        {displayedWorks.map(work => (
                            <a key={work.sn} href={`/work/${work.title}`} className="WorksOverview-item WorksOverview-work-item">
                                <div className="WorksOverview-item WorksOverview-work-item WorksOverview-works">
                                    <div className='WorksOverview-work-item-img'>
                                        <svg className='wo-svg'>
                                            <defs>
                                                <filter id="pixelate">
                                                    <feFlood x="4" y="4" height="2" width="2" />
                                                    <feComposite width="10" height="10" />
                                                    <feTile result="a" />
                                                    <feComposite in="SourceGraphic" in2="a" operator="in" />
                                                    <feMorphology operator="dilate" radius="5" />
                                                </filter>
                                            </defs>
                                        </svg>
                                        <div className={`pixelate-skeleton ${work.isLoaded ? 'pixelate-img-hidden' : ''}`}></div>
                                        <img
                                            className={work.isLoaded ? '' : 'pixelate-img-hidden'}
                                            src={work.isLoaded ? work.loadedSrc : undefined}
                                            alt={work.title}
                                        />
                                    </div>
                                    <div className='WorksOverview-work-item-title'>
                                        <h3 className='WorksOverview-disable'>{work.title}</h3>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default WorksOverview;