import React, { useEffect, useState } from 'react';
import NavigationBar from '../nav/NavBar';
import './info.css';
import VanillaTilt from 'vanilla-tilt';
import CopyrightNotice from '../corpyrignt/CopyrightNotice';
import bkimg from '../static/infobg.png';

function Info() {
    const [clientData, setClientData] = useState([]);
    const [experienceData, setExperienceData] = useState([]);
    const [exhibitionData, setExhibitionData] = useState([]);
    const [awardsData, setAwardsData] = useState([]);
    const [infoData, setInfoData] = useState([]);
    const [clientLanguage, setClientLanguage] = useState('en');
    const [experienceLanguage, setExperienceLanguage] = useState('en');
    const [exhibitionLanguage, setExhibitionLanguage] = useState('en');
    const [awardsLanguage, setAwardsLanguage] = useState('en');
    const [infoLanguage, setInfoLanguage] = useState('en');

    useEffect(() => {
        fetchData('/api/client', setClientData);
        fetchData('/api/experience', setExperienceData);
        fetchData('/api/exhibition', setExhibitionData);
        fetchData('/api/awards', setAwardsData);
        fetchData('/api/info', setInfoData);
    }, []);

    const fetchData = (url, setData) => {
        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(data => {
                const sortedData = data.sort((a, b) => a.sn - b.sn);
                setData(sortedData);
            })
            .catch(error => console.error('Error fetching data: ', error));
    };

    VanillaTilt.init(document.querySelectorAll(".box"), {
        max: 25,
        speed: 400,
        easing: "cubic-bezier(.03,.98,.52,.99)",
        perspective: 1000,
        Transition: true,
    });

    const toggleLanguage = (setLanguage) => {
        setLanguage(prevLanguage => prevLanguage === 'en' ? 'zh' : 'en');
    };

    return (
        <main>
            <CopyrightNotice />
            <div className='nav-wrapper'>
                <NavigationBar />
            </div>
            <div className='info-main' style={{backgroundImage:bkimg}}>
                <div className='left'>
                    <div className='client leftin'>
                        <div className='lefth'>
                            <h1 className='title'>Client</h1>
                            <button className='ench' onClick={() => toggleLanguage(setClientLanguage)}>中 / E</button>
                        </div>
                        <div className='left_in'>
                            {clientData.map(item => (
                                <div className='info-word' key={item.sn}>{item[`client_${clientLanguage}`]}</div>
                            ))}
                        </div>
                    </div>
                    <div className='experience leftin'>
                        <div className='lefth'>
                            <h1 className='title'>EXPERIENCE</h1>
                            <button className='ench' onClick={() => toggleLanguage(setExperienceLanguage)}>中 / E</button>
                        </div>
                        <div className='left_in'>
                            {experienceData.map(item => (
                                <div className='info-word' key={item.sn}>{item[`experience_${experienceLanguage}`]}</div>
                            ))}
                        </div>
                    </div>
                    <div className='exhibition leftin'>
                        <div className='lefth'>
                            <h1 className='title'>EXHIBITION</h1>
                            <button className='ench' onClick={() => toggleLanguage(setExhibitionLanguage)}>中 / E</button>
                        </div>
                        <div className='left_in'>
                            {exhibitionData.map(item => (
                                <div className='info-word' key={item.sn}>{item[`exhibition_${exhibitionLanguage}`]}</div>
                            ))}
                        </div>
                    </div>
                    <div className='awards leftin'>
                        <div className='lefth'>
                            <h1 className='title'>AWARDS</h1>
                            <button className='ench' onClick={() => toggleLanguage(setAwardsLanguage)}>中 / E</button>
                        </div>
                        <div className='left_in'>
                            {awardsData.map(item => (
                                <div className='info-word' key={item.sn}>{item[`awards_${awardsLanguage}`]}</div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <div className='info'>
                        <button className='ench' onClick={() => toggleLanguage(setInfoLanguage)}>中 / E</button>
                        <div className='info_in'>
                            {infoData.map(item => (
                                <div key={item.sn}>
                                    <p>{item[`info_${infoLanguage}`]}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='tel'>
                        <a href='tel:+886-902126686' target='_blank' rel="noreferrer">+886 902126686</a>
                        <a href='https://maps.app.goo.gl/SbQi6JfmhJB2S5PQ6' target='_blank' rel="noreferrer">22°43'27.1"N 120°17'53.3"E</a>
                    </div>
                    <div className='link'>
                        <a href='https://www.instagram.com/inzstudio_/' target='_blank' rel="noreferrer">inSTAGRAM</a>
                        <a href='https://www.behance.net/yinzhanhuang' target='_blank' rel="noreferrer">BEHAnCE</a>
                        <a href='mailto:inz@inz-studio.com' target='_blank' rel="noreferrer">inz@inz-studio.com</a>
                    </div>
                </div>
                <img src={bkimg} alt={'background'} className='bkimg'></img>
            </div>
        </main>
    );
}

export default Info;