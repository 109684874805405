import React from 'react';
import { BrowserRouter as Router, Route , Routes } from 'react-router-dom';
import Landingpage from './LandPage/Landingpage.js';
import WorksOverview from './work/WorksOverview.js';
import WorkDetail from './workdetail/WorkDetail.js';
import LoginPage from './login/LoginPage.js';
import Admin from './admin/admin';
import Contact from './contact/Contact.js';
import Info from './info/info.js';
import ReactGA from 'react-ga';

ReactGA.initialize('G-M5GJ2GSH9K');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Landingpage />} />
        <Route path="/works" element={<WorksOverview />} />
        <Route path="/work/:title" element={<WorkDetail />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path='/admin' element={<Admin />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/info' element={<Info />} />
      </Routes>
    </Router>
  );
}

// todo list:
// /info

export default App;