import NavigationBar from "../nav/NavBar";
import "./contact.css";
import React, { useState } from 'react';
import axios from "axios";
import CopyrightNotice from '../corpyrignt/CopyrightNotice';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [buttonText, setButtonText] = useState('submit');
    const [buttonStyle, setButtonStyle] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText('Sending...');
        setButtonStyle({ backgroundColor: '' }); // 設置為灰色表示正在處理中

        try {
            //eslint-disable-next-line
            const response = await axios.post('/api/send-email', {
                name: formData.name,
                email: formData.email,
                message: formData.message,
                to: "yinzhanet@gmail.com"
            });
            // 處理成功
            setButtonText('Thank you!');
            setButtonStyle({ backgroundColor: '#38E8D5' , color: 'black' });
            setTimeout(() => {
                setButtonText('submit');
                setButtonStyle({});
            }, 5000);
        } catch (error) {
            // 處理錯誤
            setButtonText('Error please try again later');
            setButtonStyle({ backgroundColor: 'red' });
            setTimeout(() => {
                setButtonText('submit');
                setButtonStyle({});
            }, 5000);
        }
    };
    
    
    return(
        <main className="Contact-main">
            <div className="Contact-container">
                <NavigationBar />
                <CopyrightNotice/>
                <div className="Contact-form-container">
                    <div className="Contact-form">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="name">Your Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    required
                                    value={formData.name}
                                    placeholder="Please enter your name"
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    value={formData.email}
                                    placeholder="Please enter your email"
                                    onChange={handleChange}
                                    pattern="^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.+[a-zA-Z]{2,6}$"
                                />
                            </div>
                            <div>
                                <label htmlFor="message">Message to be sent</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    required
                                    value={formData.message}
                                    placeholder="Please enter the message you want to send."
                                    onChange={handleChange}
                                />
                            </div>
                            <button type="submit" style={buttonStyle}>{buttonText}</button>
                        </form>
                        <div className="Contact-info">
                            <p>Or send an email to inz@inz-studio.com.</p>
                        </div>        
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Contact;