import React, { useEffect, useState } from 'react';
//eslint-disable-next-line
import {Table, Modal, Input, Button, message, Popconfirm , InputNumber } from 'antd';
import axios from 'axios';
import './EditableTable.css';
import moment from 'moment'; // 用於日期處理
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const EditableTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
//更多圖片狀態函數
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
//md狀態函數
  const [isMDEditModalVisible, setIsMDEditModalVisible] = useState(false);
  const [editingMD, setEditingMD] = useState('');
//title狀態函數
  const [isTitleEditModalVisible, setIsTitleEditModalVisible] = useState(false);
  const [editingTitle, setEditingTitle] = useState('');
//description狀態函數
  const [isDescriptionEditModalVisible, setIsDescriptionEditModalVisible] = useState(false);
  const [editingDescription, setEditingDescription] = useState('');
//link狀態函數
  const [isLinkEditModalVisible, setIsLinkEditModalVisible] = useState(false);
  const [editingLink, setEditingLink] = useState('');
//sn狀態函數
  const [isSNEditModalVisible, setIsSNEditModalVisible] = useState(false);
  const [editingSN, setEditingSN] = useState('');
//刪除狀態函數
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [deleteInput, setDeleteInput] = useState('');
// -----------------------------------------------------
//管理分類的狀態
const [isCategoriesModalVisible, setIsCategoriesModalVisible] = useState(false);
const [editingCategories, setEditingCategories] = useState(Array(7).fill(''));
// 管理時間編輯的狀態
const [isUpdateTimeModalVisible, setIsUpdateTimeModalVisible] = useState(false);
const [editingUpdateTime, setEditingUpdateTime] = useState(null);
//管理下面的資訊
const [isCreditInfoModalVisible, setIsCreditInfoModalVisible] = useState(false);
const [editingCreditInfo, setEditingCreditInfo] = useState(Array(3).fill(''));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/admin/works', {
        withCredentials: true
      });
      // 根據 sn 對資料進行排序
      const sortedData = response.data.sort((a, b) => a.sn - b.sn);
      setData(sortedData);
    } catch (error) {
      message.error('無法從後端加載數據');
    } finally {
      setLoading(false);
    }
  };
  
//sn狀態函數-----------------------------------------------------
const showSNEditModal = (record) => {
  setEditingRow(record);
  setEditingSN(record.sn);
  setIsSNEditModalVisible(true);
};

const handleSNSave = async () => {
  const isUnique = await checkSNUnique(editingSN);
  if (!isUnique) {
    message.error('該 SN 已存在，請選擇另一個');
    return;
  }
  const updatedRow = { ...editingRow, sn: editingSN };
  updateData(updatedRow);
  setIsSNEditModalVisible(false);
};

const handleSNCancel = () => {
  setIsSNEditModalVisible(false);
};

const handleSNChange = (e) => {
  setEditingSN(e.target.value);
};

const checkSNUnique = async (sn) => {
  if (!/^\d+$/.test(sn)) {
    message.error('SN 必須為純數字');
    return false;
  }
  try {
    const response = await axios.get(`/api/admin/works/check-sn?sn=${sn}`, {
      withCredentials: true
    });
    return response.data; // 假設後端返回一個布爾值表示是否唯一
  } catch (error) {
    message.error('無法驗證 SN 的唯一性');
    console.log(error);
    return false;
  }
};

//-----------------------------------------------------
  const showTitleEditModal = (record) => {
    setEditingRow(record);
    setEditingTitle(record.title);
    setIsTitleEditModalVisible(true);
  };
  
  const handleTitleSave = () => {
    // 修剪標題末尾的空格
    const trimmedTitle = editingTitle.trim();
    const updatedRow = { ...editingRow, title: trimmedTitle };
    updateData(updatedRow);
    setIsTitleEditModalVisible(false);
  };
  
  const handleTitleCancel = () => {
    setIsTitleEditModalVisible(false);
  };
  
  // 類似的函數用於處理 Description 和 Link
  const showDescriptionEditModal = (record) => {
    setEditingRow(record);
    setEditingDescription(record.description);
    setIsDescriptionEditModalVisible(true);
  };
  
  const handleDescriptionSave = () => {
    const updatedRow = { ...editingRow, description: editingDescription };
    updateData(updatedRow);
    setIsDescriptionEditModalVisible(false);
  };
  
  const handleDescriptionCancel = () => {
    setIsDescriptionEditModalVisible(false);
  };
  
  const showLinkEditModal = (record) => {
    setEditingRow(record);
    setEditingLink(record.link);
    setIsLinkEditModalVisible(true);
  };
  
  const handleLinkSave = () => {
    const updatedRow = { ...editingRow, link: editingLink };
    updateData(updatedRow);
    setIsLinkEditModalVisible(false);
  };
  
  const handleLinkCancel = () => {
    setIsLinkEditModalVisible(false);
  };
  //-----------------------------------------------------
  //更多註解部分-----------------------------------------------------
  const showMDEditModal = (record) => {
    setEditingRow(record);
    setEditingMD(record.md);
    setIsMDEditModalVisible(true);
  };
  
  // const handleMDSave = () => {
  //   const updatedRow = { ...editingRow, md: editingMD };
  //   updateData(updatedRow);
  //   setIsMDEditModalVisible(false);
  // };
  const handleMDSave = () => {
    const updatedRow = { ...editingRow, md: editingMD };
    updateData(updatedRow);
    setIsMDEditModalVisible(false);
  };
  
  const handleMDCancel = () => {
    setIsMDEditModalVisible(false);
  };
  
  // const handleMDChange = (e) => {
  //   setEditingMD(e.target.value);
  // };
  const handleMDChange = (value) => {
    setEditingMD(value);
  };
  //-----------------------------------------------------
  // 更多圖片部分-----------------------------------------------------
  const showEditModal = (record) => {
    setEditingRow({ ...record });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = async () => {
    if (editingRow) {
      updateData(editingRow);
    }
    setIsModalVisible(false);
  };

  const handleInputChange = (value, mlKey) => {
    setEditingRow({ ...editingRow, [mlKey]: value });
  };
// -----------------------------------------------------
// 刪除部分-----------------------------------------------------
const showDeleteConfirm = (record) => {
  setDeleteRecord(record);
  setDeleteConfirmVisible(true);
};

const handleDeleteConfirmCancel = () => {
  setDeleteConfirmVisible(false);
  setDeleteInput('');
};

const handleDeleteConfirmOk = () => {
  if (deleteRecord && deleteInput === deleteRecord.title) {
    deleteData(deleteRecord._id);
    setDeleteConfirmVisible(false);
    setDeleteInput('');
  } else {
    message.error('輸入錯誤，無法刪除');
  }
};
// -----------------------------------------------------
// 確保至少有七個輸入框
const initializeCategories = (categories) => {
  categories = categories || []; // 如果 categories 是 null 或 undefined,將其轉換為空數組
  return [...categories, ...Array(Math.max(5 - categories.length, 0)).fill('')];
};

// 分類編輯按鈕觸發函數
const showCategoriesEditModal = (record) => {
  setEditingRow(record);
  setEditingCategories(initializeCategories(record.categories || [])); // 如果 record.categories 是 null 或 undefined,將其轉換為空數組
  setIsCategoriesModalVisible(true);
};

// 分類保存處理函數
const handleCategoriesSave = async () => {
  const updatedCategories = editingCategories.map(cat => cat.trim()).filter(cat => cat);
  if (updatedCategories.length === 0) {
    updatedCategories.push("未分類");
  }
  const updatedRow = { ...editingRow, categories: updatedCategories };
  await updateData(updatedRow);
  setIsCategoriesModalVisible(false);
};

// 分類輸入處理函數
const handleCategoryChange = (index, value) => {
  const updatedCategories = [...editingCategories];
  updatedCategories[index] = value;
  setEditingCategories(updatedCategories);
};

// 添加新的分類輸入框
const addCategoryInput = () => {
  setEditingCategories([...editingCategories, '']);
};

// 時間編輯按鈕觸發函數
const showUpdateTimeEditModal = (record) => {
  setEditingRow(record);
  // 轉換成現有時間或是當前的午夜
  setEditingUpdateTime(record.update_Time ? moment(record.update_Time).toDate() : moment().startOf('day').toDate());
  setIsUpdateTimeModalVisible(true);
};

// 處理日期選擇
const handleUpdateTimeChange = (date) => {
  if (date) {
    const localDate = moment(date).startOf('day'); 
    setEditingUpdateTime(localDate.toDate());
  } else {
    setEditingUpdateTime(null);
  }
};

// 保存時間
const handleUpdateTimeSave = async () => {
  const updatedRow = { ...editingRow };
  if (editingUpdateTime) {
    updatedRow.update_Time = moment(editingUpdateTime).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  } else {
    updatedRow.update_Time = null;
  }
  await updateData(updatedRow);
  setIsUpdateTimeModalVisible(false);
};

// 確保至少有五個輸入框
const initializeCreditInfo = (creditInfo) => {
  creditInfo = creditInfo || []; // 如果 creditInfo 是 null 或 undefined,將其轉換為空數組
  return [...creditInfo, ...Array(Math.max(3 - creditInfo.length, 0)).fill('')];
};

// creditinfo 編輯按鈕觸發函數
const showCreditInfoEditModal = (record) => {
  setEditingRow(record);
  setEditingCreditInfo(initializeCreditInfo(record.creditinfo || [])); // 如果 record.creditinfo 是 null 或 undefined,將其轉換為空數組
  setIsCreditInfoModalVisible(true);
};

// creditinfo 保存處理函數
const handleCreditInfoSave = async () => {
  const updatedCreditInfo = editingCreditInfo.map(info => info.trim()).filter(info => info);
  const updatedRow = { ...editingRow, creditinfo: updatedCreditInfo };
  await updateData(updatedRow);
  setIsCreditInfoModalVisible(false);
};

// creditinfo 輸入處理函數
const handleCreditInfoChange = (index, value) => {
  const updatedCreditInfo = [...editingCreditInfo];
  updatedCreditInfo[index] = value;
  setEditingCreditInfo(updatedCreditInfo);
};
// -----------------------------------------------------


  // 定義表格列sn title description link md ml1到ml10
  const columns = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
      width:"1%",
      render: (text, record) => (
        <Button type="link" onClick={() => showSNEditModal(record)}>
          {text || "編輯sn"}
        </Button>
      ),
    },
    {
      title: '標題',
      dataIndex: 'title',
      key: 'title',
      width:"15%",
      render: (text, record) => (
        <Button type="link" onClick={() => showTitleEditModal(record)}>
          {text || "編輯標題"}
        </Button>
      ),
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
      width:"5%",
      render: (text, record) => (
        <Button type="link" onClick={() => showDescriptionEditModal(record)}>
          {text || "編輯描述"}
        </Button>
      ),
    },
    {
      title: '修改年分',
      dataIndex: 'update_Time',
      key: 'update_Time',
      width:"10%",
      render: (text, record) => (
        <Button type="link" onClick={() => showUpdateTimeEditModal(record)}>
          {text ? moment(text).format("YYYY") : "編輯年分"}
        </Button>
      ),
    },
    {
      title: '首頁展示圖片',
      dataIndex: 'link',
      key: 'link',
      widtgh:"15%",
      render: (text, record) => (
        // <Button type="link" onClick={() => showLinkEditModal(record)}>
        //   {text || "編輯連結"}
        // </Button>  
        <Button onClick={() => showLinkEditModal(record)}>
          編輯圖片
        </Button>  
      ),
    },
    {
      title: '詳細展示頁圖片',
      key: 'mlEdit',
      widtgh:"11.5%",
      render: (text, record) => <Button onClick={() => showEditModal(record)}>編輯圖片</Button>
    },
    {
      title:'分類',
      key:'categories',
      width:"8%",
      render: (text, record) => (
        // <Button type="link" onClick={() => showCategoriesEditModal(record)}>
        //   {record.categories ? record.categories.join(', ') : '未分類'}
        //   {/* {"編輯分類"} */}
        // </Button>
        <Button onClick={() => showCategoriesEditModal(record)}>
          編輯分類
        </Button>
      ),
    },
    {
      title: '更多註解(展示頁下方)',
      key: 'mdEdit',
      widtgh:"6.5%",
      render: (text, record) => <Button onClick={() => showMDEditModal(record)}>編輯更多註解</Button>
    },
    {
      title: 'Credit Info',
      key: 'creditinfo',
      width: '8%',
      render: (text, record) => (
        // <Button type="link" onClick={() => showCreditInfoEditModal(record)}>
        //   {record.creditinfo ? record.creditinfo.join(', ') : '編輯Credit Info'}
        // </Button>
        <Button onClick={() => showCreditInfoEditModal(record)}>
          編輯Credit Info
        </Button>
      ),
    },
    {
      title: '操作',
      key: 'action',
      width:"2%",
      render: (text, record) => (
        <>
          <Button onClick={() => showDeleteConfirm(record)}>刪除</Button>
        </>
      )
    }
  ];

  // 更新數據的函數
  const updateData = async (updatedRow) => {
    try {
      await axios.put(`/api/admin/works/${updatedRow._id}`, updatedRow, {
        withCredentials: true
      });
      fetchData();
      message.success('更新成功');
    } catch (error) {
      message.error('更新失敗');
    }
  };
  
  // 刪除數據的函數
  //eslint-disable-next-line
  const deleteData = async (id) => {
    try {
      await axios.delete(`/api/admin/delete/works/${id}`, {
        withCredentials: true
      });
      fetchData();
      message.success('刪除成功');
    } catch (error) {
      message.error('刪除失敗');
      console.error('刪除錯誤:', error);
    }
  };
  

  // 這裡可以添加更多函數來處理數據的新增和編輯

  return (
    <>
      <Table 
        dataSource={data} 
        columns={columns} 
        loading={loading} 
        rowKey="sn" 
        pagination={false}
        widtgh="100%"
      />
      <Modal
        title="編輯 SN"
        open={isSNEditModalVisible}
        onOk={handleSNSave}
        onCancel={handleSNCancel}
        footer={[
          <Button className='editbutton cancel-btn' key="cancel" onClick={handleSNCancel}>取消</Button>,
          <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleSNSave}>儲存</Button>,
        ]}
      >
        <Input
          value={editingSN} 
          onChange={handleSNChange} 
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSNSave();
            }else if (e.key === 'Escape') {
              handleSNCancel();
            }
          }}
        />
      </Modal>
      {/* Title 編輯對話框 */}
        <Modal
          title="編輯標題"
          open={isTitleEditModalVisible}
          onOk={handleTitleSave}
          onCancel={handleTitleCancel}
          footer={[
            <Button className='editbutton cancel-btn' key="cancel" onClick={handleTitleCancel}>取消</Button>,
            <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleTitleSave}>儲存</Button>,
          ]}
        >
          <Input 
            value={editingTitle} 
            onChange={(e) => setEditingTitle(e.target.value)} 
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleTitleSave();
              }else if (e.key === 'Escape') {
                handleTitleCancel();
              }
            }}
          />
        </Modal>

        {/* Description 編輯對話框 */}
        <Modal
          title="編輯描述"
          open={isDescriptionEditModalVisible}
          onOk={handleDescriptionSave}
          onCancel={handleDescriptionCancel}
          footer={[
            <Button className='editbutton cancel-btn' key="cancel" onClick={handleDescriptionCancel}>取消</Button>,
            <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleDescriptionSave}>儲存</Button>,
          ]}
        >
          <Input 
            value={editingDescription} 
            onChange={(e) => setEditingDescription(e.target.value)} 
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleDescriptionSave();
              }else if (e.key === 'Escape') {
                handleDescriptionCancel();
              }
            }}
          />
        </Modal>
        <Modal
          title="編輯時間"
          open={isUpdateTimeModalVisible}
          onOk={handleUpdateTimeSave}
          onCancel={() => setIsUpdateTimeModalVisible(false)}
          className='data-picker'
          footer={[
            <Button className='editbutton cancel-btn' key="cancel" onClick={() => setIsUpdateTimeModalVisible(false)}>取消</Button>,
            <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleUpdateTimeSave}>儲存</Button>
          ]}
        >
          <ReactDatePicker
            selected={editingUpdateTime}
            onChange={handleUpdateTimeChange}
            dateFormat="yyyy-MM-dd"  // 只顯示日期
            className="form-control data-picker"
          />
        </Modal>
        {/* Link 編輯對話框 */}
        <Modal
          title="編輯展示圖片"
          open={isLinkEditModalVisible}
          onOk={handleLinkSave}
          onCancel={handleLinkCancel}
          footer={[
            <Button className='editbutton cancel-btn' key="cancel" onClick={handleLinkCancel}>取消</Button>,
            <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleLinkSave}>儲存</Button>,
          ]}
        >
          <Input 
            value={editingLink} 
            onChange={(e) => setEditingLink(e.target.value)} 
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLinkSave();
              }else if (e.key === 'Escape') {
                handleLinkCancel();
              }
            }}
          />
        </Modal>
        <Modal
          title="編輯分類"
          open={isCategoriesModalVisible}
          onOk={handleCategoriesSave}
          onCancel={() => setIsCategoriesModalVisible(false)}
          footer={[
            <Button key="add" onClick={addCategoryInput}>新增分類</Button>,
            <Button className='editbutton cancel-btn' key="cancel" onClick={() => setIsCategoriesModalVisible(false)}>取消</Button>,
            <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleCategoriesSave}>儲存</Button>
          ]}
        >
          {editingCategories.map((category, index) => (
            <Input
              key={index}
              value={category}
              addonBefore={'#'}
              onChange={(e) => handleCategoryChange(index, e.target.value)}
              style={{ marginBottom: '10px' }}
              placeholder={`分類 ${index + 1}`}
            />
          ))}
        </Modal>
        <Modal
          title="編輯更多作品訊息"
          open={isMDEditModalVisible}
          onOk={handleMDSave}
          onCancel={handleMDCancel}
          footer={[
            <Button className='editbutton cancel-btn' key="cancel" onClick={handleMDCancel}>取消</Button>,
            <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleMDSave}>儲存</Button>,
          ]}
        >
          <ReactQuill
            value={editingMD}
            onChange={handleMDChange}
            style={{ width: '50em',height: '20em'}}
          />
          {/* <Input.TextArea
            value={editingMD}
            onChange={handleMDChange}
            rows={4}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleMDSave();
              }else if (e.key === 'Escape') {
                handleMDCancel();
              }
            }}
          /> */}
        </Modal>
        <Modal
          title="編輯 Credit Info"
          open={isCreditInfoModalVisible}
          onOk={handleCreditInfoSave}
          onCancel={() => setIsCreditInfoModalVisible(false)}
          footer={[
            <Button className="editbutton cancel-btn" key="cancel" onClick={() => setIsCreditInfoModalVisible(false)}>
              取消
            </Button>,
            <Button className="editbutton submit-button" key="submit" type="primary" onClick={handleCreditInfoSave}>
              儲存
            </Button>,
          ]}
        >
          {editingCreditInfo.map((info, index) => (
            <Input
              key={index}
              value={info}
              onChange={(e) => handleCreditInfoChange(index, e.target.value)}
              style={{ marginBottom: '10px' }}
              placeholder={`Credit右邊的第${index + 1}格`}
            />
          ))}
        </Modal>
        <Modal
          title="編輯更多圖片連結"
          open={isModalVisible}
          onOk={handleSave}
          onCancel={handleCancel}
          footer={[
            <Button className='editbutton cancel-btn' key="back" onClick={handleCancel}>取消</Button>,
            <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleSave}>儲存</Button>,
          ]}
        >
          {[...Array(10)].map((_, index) => {
            const mlKey = `ml${index + 1}`;
            const mlid = `圖片${index + 1}`;
            return (
              <Input
                key={mlKey}
                id={mlKey}
                addonBefore={mlid}
                value={editingRow ? editingRow[mlKey] : ''}
                onChange={(e) => handleInputChange(e.target.value, mlKey)}
                style={{ marginBottom: '10px' }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }else if (e.key === 'Escape') {
                    handleCancel();
                  }else if (e.key === 'Tab') {
                    e.preventDefault();
                    const nextMLKey = `ml${index + 2}`;
                    const nextMLInput = document.getElementById(nextMLKey);
                    const maxMLKey = `ml${11}`;
                    const firstMLInput = document.getElementById('ml1');
                    if (nextMLInput) {
                      nextMLInput.focus();
                    }else if(nextMLKey === maxMLKey){
                      firstMLInput.focus();
                    }
                  }
                }}
              />
            );
          })}
      </Modal>
      <Modal
        title="確認刪除"
        open={deleteConfirmVisible}
        onOk={handleDeleteConfirmOk}
        onCancel={handleDeleteConfirmCancel}
        footer={[
          <Button className='editbutton cancel-btn' key="back" onClick={handleDeleteConfirmCancel}>取消</Button>,
          <Button className='editbutton submit-button delete-button' key="submit" type="primary" onClick={handleDeleteConfirmOk}>刪除</Button>,
        ]}
      >
        <p>您確定要刪除 {deleteRecord?.title} 的資料嗎？</p>
        <p>請輸入 {deleteRecord?.title} 以確認刪除:</p>
        <Input 
          value={deleteInput} 
          onChange={(e) => setDeleteInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleDeleteConfirmOk();
            }else if (e.key === 'Escape') {
              handleDeleteConfirmCancel();
            }
          }}
        />
      </Modal>
    </>
  );
};

export default EditableTable;
