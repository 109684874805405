import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NavigationBar from '../nav/NavBar';
import './WorkDetail.css';
import CopyrightNotice from '../corpyrignt/CopyrightNotice';
import CustomDirective from './CustomDirective';

const WorkDetail = () => {
    const { title } = useParams();
    const [work, setWork] = useState(null);

    useEffect(() => {
        fetch(`/api/work/${title}`)
            .then(response => response.json())
            .then(data => {
                setWork(data)
            })
            .catch(error => console.error('Error fetching work details: ', error));
    }, [title]);

    // 禁用右键菜单
    const handleContextMenu = (event) => {
        // 阻止默認的右鍵菜單
        event.preventDefault();
        // 嘗試顯示標準右鍵菜單
        // 注意：這不會在所有瀏覽器中都起作用
        document.dispatchEvent(new MouseEvent("contextmenu", { 
          bubbles: true, 
          cancelable: true,
          view: window, 
          clientX: event.clientX, 
          clientY: event.clientY 
        }));
      };

    if (!work) {
        return <div>Loading...</div>;
    }
    const renderCategories = () => {
        if (!work.categories || work.categories.length === 0) {
            return (
                <div className='wd-cate'>
                    <p>#未分類</p>
                </div>
                );
        } else {
            return (
                <div className='wd-cate'>
                    {work.categories.map((category, index) => (
                        <p key={index}>{'#' + category}</p>
                    ))}
                </div>
            );
        }
    };

    //渲染 ML1-10圖片
    const renderMLImages = () => {
        const images = [];
        for (let i = 1; i <= 10; i++) {
            const imageUrl = work[`ml${i}`];
            if (imageUrl) {
                images.push(
                    <CustomDirective key={i}>
                        <div className='WorkDetail-img WorkDetail-ml'>
                            <img key={i} src={imageUrl} className='lazyload' alt={`${work.title} ${i}`} />
                        </div>
                    </CustomDirective>
                );
            }
        }
        return images;
    };
    
    const renderCreditInfo = () => {
        if (!work.creditinfo || work.creditinfo.length === 0) {
            // 如果 creditinfo 数组为空或不存在，则不渲染任何内容
            return null;
        }

        return (
            <div className='WorkDetail-creditinfo-in wd-cr'>
                {work.creditinfo.map((credit, index) => (
                    <div key={index} className='WorkDetail-creditinfo-item wd-critem'>
                        <p>{credit}</p>
                    </div>
                ))}
            </div>
        );
    };
    // 從update_time中提取年份
    const getYearFromUpdateTime = () => {
        if (work.update_Time) {
            const date = new Date(work.update_Time);
            const fullyear = date.getFullYear();
            return (
                <div className='WorkDetail-creditinfo-in wd-y'>
                    <div className='WorkDetail-creditinfo-item wd-year'>
                        <p className='wd-credit'>Credit</p>
                        <p className='wd-year'>Year&nbsp;:&nbsp;{fullyear}</p>
                    </div>
            </div>
            );
        }
        return '';
    };

    return (
        <main className='WorkDetail-main'>
            <div className='WorkDetail-container'>
                <NavigationBar />
                <CopyrightNotice/>
                <div className='WorkDetail-main-view' onContextMenu={handleContextMenu} >
                    <div className='WorkDetail-text WorkDetail-title'>
                        <h1>{work.title}</h1>
                        <div className='wd-categories'>
                            {renderCategories()}
                        </div>
                    </div>
                    {/* <div className='WorkDetail-img'>
                        <img src={work.link} alt={work.title} />
                    </div>  */}
                    {renderMLImages()}
                    <div className='WorkDetail-text WorkDetail-creditinfo'>
                        {getYearFromUpdateTime()}
                        {renderCreditInfo()}
                        <div className='WorkDetail-md'
                            dangerouslySetInnerHTML={{ __html: work.md }}>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default WorkDetail;
