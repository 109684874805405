import React, { Component } from 'react';
import './CopyrightNotice.css';

// 防抖工具函数
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

class CopyrightNotice extends Component {
  constructor(props) {
    super(props);
    this.resizeObserver = null;
    this.mutationObserver = null;
  }

  componentDidMount() {
    this.updateLogoWallHeight();
    window.addEventListener('resize', this.updateLogoWallHeight);

    this.resizeObserver = new ResizeObserver(() => {
      this.updateLogoWallHeight();
    });

    const logoWall = document.querySelector('.logo-wall');
    if (logoWall) {
      this.resizeObserver.observe(logoWall);
    }

    this.mutationObserver = new MutationObserver(() => {
      this.updateLogoWallHeight();
    });

    if (logoWall) {
      this.mutationObserver.observe(logoWall, {
        childList: true,
        subtree: true,
        attributes: true
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLogoWallHeight);
  
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }
  

// 在组件中使用
updateLogoWallHeight = debounce(() => {
  const logoWall = document.querySelector('.logo-wall');
  const first = document.querySelector('.first');
  const second = document.querySelector('.second');
  const h = document.body.clientHeight - 100;

  if (logoWall) {
    logoWall.style.height = `${h}px`;
    first.style.height = `${h / 2}px`;
    second.style.height = `${h / 2}px`;
    first.style.margin = `0px 0px ${h / 2}px 0px`;
  }
}, 100); // 可以根据需要调整延迟时间

    render() {
        return (
            <div className="logo-wall">
            <div className="logo-wrapper first">
                <div className="logo">
                    <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Copyright © 2021-2024 inz studio. All rights reserved.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </div>
            </div>
            <div className="logo-wrapper second">
                <div className="logo">
                    <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Copyright © 2021-2024 inz studio. All rights reserved.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </div>
            </div>
            </div>
        );
    }
}

export default CopyrightNotice;
