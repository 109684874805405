import React, { useState } from 'react';
import { Table, Button, Input, message } from 'antd';
import axios from 'axios';
import './Newwork.css';
import { Modal } from 'antd';
import moment from 'moment/moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Newwork = () => {
    const [data, setData] = useState([]);
    //eslint-disable-next-line
    const [isAdding, setIsAdding] = useState(false);
    //eslint-disable-next-line
    const [newRow, setNewRow] = useState({});
    const [editingRow, setEditingRow] = useState(null); // 当前正在编辑的行
    const [editingSN, setEditingSN] = useState(''); // 编辑中的 SN
    const [editingTitle, setEditingTitle] = useState(''); // 编辑中的标题
    const [editingDescription, setEditingDescription] = useState(''); // 编辑中的描述
    const [editingLink, setEditingLink] = useState(''); // 编辑中的链接
    const [editingMD, setEditingMD] = useState(''); // 编辑中的MD
    const [editingML, setEditingML] = useState({ ml1: '', ml2: '', ml3: '', ml4:'',ml5:'',ml6: '', ml7: '', ml8: '', ml9:'',ml10:'' });/* 以此类推直到 ml10 */
    const [isSNEditModalVisible, setIsSNEditModalVisible] = useState(false); // SN 编辑弹窗是否可见
    const [isTitleEditModalVisible, setIsTitleEditModalVisible] = useState(false); // 标题 编辑弹窗是否可见
    const [isDescriptionEditModalVisible, setIsDescriptionEditModalVisible] = useState(false); // 描述 编辑弹窗是否可见
    const [isLinkEditModalVisible, setIsLinkEditModalVisible] = useState(false); // 链接 编辑弹窗是否可见
    const [isMDEditModalVisible, setIsMDEditModalVisible] = useState(false); // MD 编辑弹窗是否可见
    const [isMLEditModalVisible, setIsMLEditModalVisible] = useState(false); // ML 编辑弹窗是否可见
    // 新增管理分類和時間的狀態
    const [editingCategories, setEditingCategories] = useState(Array(5).fill(''));
    const [isCategoriesModalVisible, setIsCategoriesModalVisible] = useState(false);
    const [editingUpdateTime, setEditingUpdateTime] = useState(null);
    const [isUpdateTimeModalVisible, setIsUpdateTimeModalVisible] = useState(false);
    const [isCreditInfoModalVisible, setIsCreditInfoModalVisible] = useState(false);
    const [editingCreditInfo, setEditingCreditInfo] = useState(Array(3).fill(''));

    const addNewRow = () => {
        const newRow = { sn: '', title: '', description: '', link: '', md: '',ml: '' };
        setData([...data, newRow]); // 在现有数据上添加新行
        setIsAdding(true);
    };
  
      const clearRow = (record) => {
        // 从数据数组中移除指定的行
        const newData = data.filter(row => row !== record);
        setData(newData);
      };
      
      const submitRow = async (record) => {
        if (!/^\d+$/.test(record.sn)) {
          message.error('SN 必須為純數字');
          return false;
        }
        try {
          await axios.post(`/api/add/admin/works`, record, {
            withCredentials: true
          });
          message.success('儲存成功');
          clearRow(record); // 数据发送成功后清除行
        } catch (error) {
          message.error('發送失敗');
        }
      };
  
    //定義編輯modal
    const showSNEditModal = (record) => {
        setEditingRow(record);
        setEditingSN(record.sn);
        setIsSNEditModalVisible(true);
      };
    const showTitleEditModal = (record) => {
        setEditingRow(record);
        setEditingTitle(record.title);
        setIsTitleEditModalVisible(true);
      };
    const showDescriptionEditModal = (record) => {
        setEditingRow(record);
        setEditingDescription(record.description);
        setIsDescriptionEditModalVisible(true);
      };
    const showLinkEditModal = (record) => {
        setEditingRow(record);
        setEditingLink(record.link);
        setIsLinkEditModalVisible(true);
      };
    const showMDEditModal = (record) => {
        setEditingRow(record);
        setEditingMD(record.md);
        setIsMDEditModalVisible(true);
      }; 
    const showMLEditModal = (record) => {
      setEditingRow(record);
      setEditingML(record.ml || { ml1: '', ml2: '', ml3: '', ml4:'',ml5:'',ml6: '', ml7: '', ml8: '', ml9:'',ml10:'' });
      setIsMLEditModalVisible(true);
    };
    // 分類編輯按鈕觸發函數
    const showCategoriesEditModal = (record) => {
      setEditingRow(record);
      setEditingCategories(record.categories || Array(5).fill(''));
      setIsCategoriesModalVisible(true);
    };
    // 時間編輯按鈕觸發函數
    const showUpdateTimeEditModal = (record) => {
      setEditingRow(record);
      setEditingUpdateTime(record.update_Time ? moment(record.update_Time).toDate() : moment().startOf('day').toDate());
      setIsUpdateTimeModalVisible(true);
    };
      

      const handleSNSave = () => {
        // 更新 data 数组中对应行的 sn 值
        const updatedData = data.map(row => {
          if (row === editingRow) {
            return { ...row, sn: editingSN };
          }
          return row;
        });
      
        setData(updatedData);
        setIsSNEditModalVisible(false); // 关闭 Modal
      };
      
      const handleSNCancel = () => {
        // 撤销对 SN 的更改
        setEditingSN(newRow.sn);
        setIsSNEditModalVisible(false); // 关闭 Modal
      };

      const handleTitleSave = () => {
        // 修剪標題末尾的空格
        const trimmedTitle = editingTitle.trim();
        // 更新 data 數組中對應行的 title 值
        const updatedData = data.map(row => {
          if (row === editingRow) {
            return { ...row, title: trimmedTitle };
          }
          return row;
        });
      
        setData(updatedData);
        setIsTitleEditModalVisible(false); // 關閉 Modal
      };
      
      const handleDescriptionSave = () => {
        // 更新 data 数组中对应行的 description 值
        const updatedData = data.map(row => {
          if (row === editingRow) {
            return { ...row, description: editingDescription };
          }
          return row;
        });
      
        setData(updatedData);
        setIsDescriptionEditModalVisible(false); // 关闭 Modal
      };
      const handleLinkSave = () => {
        // 更新 data 数组中对应行的 link 值
        const updatedData = data.map(row => {
          if (row === editingRow) {
            return { ...row, link: editingLink };
          }
          return row;
        });
      
        setData(updatedData);
        setIsLinkEditModalVisible(false); // 关闭 Modal
      };
      const handleMDSave = () => {
        // 更新 data 数组中对应行的 md 值
        const updatedData = data.map(row => {
          if (row === editingRow) {
            return { ...row, md: editingMD };
          }
          return row;
        });
      
        setData(updatedData);
        setIsMDEditModalVisible(false); // 关闭 Modal
      };
        const handleSave = () => {
            // 更新 data 数组中对应行的 ml 值
            const updatedData = data.map(row => {
            if (row === editingRow) {
                return { ...row, ml: editingML };
            }
            return row;
            });
        
            setData(updatedData);
            setIsMLEditModalVisible(false); // 关闭 Modal
        };
        // 分類保存處理函數
      const handleCategoriesSave = () => {
        const updatedCategories = editingCategories.map((cat) => cat.trim()).filter((cat) => cat);
        if (updatedCategories.length === 0) {
          updatedCategories.push('未分類');
        }
        const updatedData = data.map((row) => {
          if (row === editingRow) {
            return { ...row, categories: updatedCategories };
          }
          return row;
        });

        setData(updatedData);
        setIsCategoriesModalVisible(false);
      };

      // 分類輸入處理函數
      const handleCategoryChange = (index, value) => {
        const updatedCategories = [...editingCategories];
        updatedCategories[index] = value;
        setEditingCategories(updatedCategories);
      };
      // 添加新的分類輸入框
      const addCategoryInput = () => {
        setEditingCategories([...editingCategories, '']);
      };

      // 處理日期選擇
      const handleUpdateTimeChange = (date) => {
        if (date) {
          const localDate = moment(date).startOf('day');
          setEditingUpdateTime(localDate.toDate());
        } else {
          setEditingUpdateTime(null);
        }
      };
      // 保存時間
      const handleUpdateTimeSave = () => {
        const updatedData = data.map((row) => {
          if (row === editingRow) {
            return {
              ...row,
              update_Time: editingUpdateTime ? moment(editingUpdateTime).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null,
            };
          }
          return row;
        });

        setData(updatedData);
        setIsUpdateTimeModalVisible(false);
      };
          // 確保至少有三個輸入框
    const initializeCreditInfo = (creditInfo) => {
      creditInfo = creditInfo || []; // 如果 creditInfo 是 null 或 undefined,將其轉換為空數組
      return [...creditInfo, ...Array(Math.max(3 - creditInfo.length, 0)).fill('')];
    };

    // creditinfo 編輯按鈕觸發函數
    const showCreditInfoEditModal = (record) => {
        setEditingRow(record);
        setEditingCreditInfo(initializeCreditInfo(record.creditinfo || [])); // 如果 record.creditinfo 是 null 或 undefined,將其轉換為空數組
        setIsCreditInfoModalVisible(true);
    };

    // creditinfo 保存處理函數
    const handleCreditInfoSave = () => {
        const updatedCreditInfo = editingCreditInfo.map(info => info.trim()).filter(info => info);
        const updatedData = data.map((row) => {
            if (row === editingRow) {
                return { ...row, creditinfo: updatedCreditInfo };
            }
            return row;
        });

        setData(updatedData);
        setIsCreditInfoModalVisible(false);
    };

    // creditinfo 輸入處理函數
    const handleCreditInfoChange = (index, value) => {
        const updatedCreditInfo = [...editingCreditInfo];
        updatedCreditInfo[index] = value;
        setEditingCreditInfo(updatedCreditInfo);
    };
  
    // 定義表格列
    const columns = [
        {
        title: 'SN',
        dataIndex: 'sn',
        key: 'sn',
        width:"2%",
        render: (text, record) => (
            <Button type="link" onClick={() => showSNEditModal(record)}>
            {text || '编辑 SN'}
            </Button>
        ),
        },
        {
        title: '標題',
        dataIndex: 'title',
        key: 'title',
        width: '15%',
        render: (text, record) => (
        <Button type="link" onClick={() => showTitleEditModal(record)}>
            {text || '编辑標題'}
        </Button>
        )
        },
        {
        title: '描述',
        dataIndex: 'description',
        key: 'description',
        width: '10%',
        render: (text, record) => (
        <Button type="link" onClick={() => showDescriptionEditModal(record)}>
            {text || '编辑描述'}
        </Button>
        ),
        },
        {
          title: '修改日期',
          dataIndex: 'update_Time',
          key: 'update_Time',
          width: '15%',
          render: (text, record) => (
            <Button type="link" onClick={() => showUpdateTimeEditModal(record)}>
              {text ? moment(text).format('YYYY-MM-DD') : '編輯日期'}
            </Button>
          ),
        },
        {
        title: '展示圖片',
        dataIndex: 'link',
        key: 'link',
        width: '15%',
        render: (text, record) => (
        // <Button type="link" onClick={() => showLinkEditModal(record)}>
        //     {text || '编辑連結'}
        // </Button>
          <Button onClick={() => showLinkEditModal(record)}>
            编辑連結
          </Button>
        ),
        },
        {
          title: '分類',
          key: 'categories',
          width: '8%',
          render: (_, record) => (
            <Button onClick={() => showCategoriesEditModal(record)}>
              編輯分類
            </Button>
          ),
        },
        {
        title: '更多註解(詳細展示頁)',
        dataIndex: 'md',
        key: 'mdEdit',
        widtgh:"6.5%",
        render: (_, record) => (
            <Button onClick={() => showMDEditModal(record)}>编辑更多註解</Button>
          ),
        },
        {
          title: 'Credit部分的Info',
          key: 'creditinfo',
          width: '8%',
          render: (_, record) => (
              <Button onClick={() => showCreditInfoEditModal(record)}>
                  編輯Credit Info
              </Button>
          ),
      },
        {
        title: '更多圖片(詳細展示頁)',
        key: 'mlEdit',
        widtgh:"6.5%",
        render: (_, record) => (
            <Button onClick={() => showMLEditModal(record)}>编辑更多圖片</Button>
        )
        },
        {
        title: '操作',
        key: 'action',
        width: '7%',
        render: (_, record) => (
        <>
            <Button onClick={() => clearRow(record)}>清除</Button>
            <Button onClick={() => submitRow(record)} type="primary">送出</Button>
        </>
        ),
        }
    ];
  
    return (
        <>
        <Button onClick={addNewRow} type='primary' className='addworks-addbtn'>新增作品</Button>
        <Table 
            dataSource={data} 
            columns={columns} 
            rowKey="sn" 
            pagination={false}
            widtgh="80%"
        />
        <Modal
            title="编辑 SN"
            open={isSNEditModalVisible}
            onOk={() => {handleSNSave(editingRow); setIsSNEditModalVisible(false)}} // 点击确定时的回调
            onCancel={() => {handleSNCancel(); setIsSNEditModalVisible(false)}}
            footer={[
                <Button className='editbutton' key="cancel" onClick={handleSNCancel}>取消</Button>,
                <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleSNSave}>储存</Button>,
              ]}
            >
            <Input 
              value={editingSN} 
              onChange={e => setEditingSN(e.target.value)} 
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSNSave();
                }else if (e.key === 'Escape') {
                  handleSNCancel();
                }
              }}
            />
        </Modal>
        <Modal
            title="编辑標題"
            open={isTitleEditModalVisible}
            onOk={() => {handleTitleSave(); setIsTitleEditModalVisible(false)}}
            onCancel={() => setIsTitleEditModalVisible(false)}
            footer={[
                <Button className='editbutton' key="cancel" onClick={() => setIsTitleEditModalVisible(false)}>取消</Button>,
                <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleTitleSave}>儲存</Button>,
              ]}
            >
            <Input 
              value={editingTitle} 
              onChange={e => setEditingTitle(e.target.value)} 
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleTitleSave();
                }else if (e.key === 'Escape') {
                  setIsTitleEditModalVisible(false);
                }
              }}
            />
        </Modal>
        <Modal
            title="编辑描述"
            open={isDescriptionEditModalVisible}
            onOk={() => {handleDescriptionSave(); setIsDescriptionEditModalVisible(false)}}
            onCancel={() => setIsDescriptionEditModalVisible(false)}
            footer={[
                <Button className='editbutton' key="cancel" onClick={() => setIsDescriptionEditModalVisible(false)}>取消</Button>,
                <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleDescriptionSave}>儲存</Button>,
              ]}
            >
            <Input 
              value={editingDescription} 
              onChange={e => setEditingDescription(e.target.value)} 
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleDescriptionSave();
                }else if (e.key === 'Escape') {
                  setIsDescriptionEditModalVisible(false);
                }
              }}
            />
        </Modal>
        <Modal
          title="編輯日期"
          open={isUpdateTimeModalVisible}
          onOk={handleUpdateTimeSave}
          onCancel={() => setIsUpdateTimeModalVisible(false)}
          className="data-picker"
          footer={[
            <Button className="editbutton cancel-btn" key="cancel" onClick={() => setIsUpdateTimeModalVisible(false)}>
              取消
            </Button>,
            <Button className="editbutton submit-button" key="submit" type="primary" onClick={handleUpdateTimeSave}>
              儲存
            </Button>,
          ]}
        >
          <ReactDatePicker
            selected={editingUpdateTime}
            onChange={handleUpdateTimeChange}
            dateFormat="yyyy-MM-dd"
            className="form-control data-picker"
          />
      </Modal>
        <Modal
            title="編輯展示圖片"
            open={isLinkEditModalVisible}
            onOk={() => {handleLinkSave(); setIsLinkEditModalVisible(false)}}
            onCancel={() => setIsLinkEditModalVisible(false)}
            footer={[
                <Button className='editbutton' key="cancel" onClick={() => setIsLinkEditModalVisible(false)}>取消</Button>,
                <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleLinkSave}>儲存</Button>,
              ]}
            >
            <Input 
              value={editingLink} 
              onChange={e => setEditingLink(e.target.value)} 
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLinkSave();
                }else if (e.key === 'Escape') {
                  setIsLinkEditModalVisible(false);
                }
              }}
            />
        </Modal>
        <Modal
          title="編輯分類"
          open={isCategoriesModalVisible}
          onOk={handleCategoriesSave}
          onCancel={() => setIsCategoriesModalVisible(false)}
          footer={[
            <Button key="add" onClick={addCategoryInput}>
              新增分類
            </Button>,
            <Button className="editbutton cancel-btn" key="cancel" onClick={() => setIsCategoriesModalVisible(false)}>
              取消
            </Button>,
            <Button className="editbutton submit-button" key="submit" type="primary" onClick={handleCategoriesSave}>
              儲存
            </Button>,
          ]}
        >
          {editingCategories.map((category, index) => (
            <Input
              key={index}
              value={category}
              addonBefore={'#'}
              onChange={(e) => handleCategoryChange(index, e.target.value)}
              style={{ marginBottom: '10px' }}
              placeholder={`分類 ${index + 1}`}
            />
          ))}
        </Modal>
        <Modal
            title="编辑更多註解"
            open={isMDEditModalVisible}
            onOk={() => {handleMDSave(); setIsMDEditModalVisible(false)}}
            onCancel={() => setIsMDEditModalVisible(false)}
            footer={[
                <Button className='editbutton' key="cancel" onClick={() => setIsMDEditModalVisible(false)}>取消</Button>,
                <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleMDSave}>儲存</Button>,
              ]}
            >
            <ReactQuill
              value={editingMD}
              onChange={value => setEditingMD(value)}
              style={{ width: '50em',height: '20em'}}
            />
            {/* <Input.TextArea 
                value={editingMD} 
                onChange={e => setEditingMD(e.target.value)} 
                rows={4}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleMDSave();
                  }else if (e.key === 'Escape') {
                    setIsMDEditModalVisible(false);
                  }
                }}
            /> */}
        </Modal>
        <Modal
            title="編輯 Credit Info"
            open={isCreditInfoModalVisible}
            onOk={handleCreditInfoSave}
            onCancel={() => setIsCreditInfoModalVisible(false)}
            footer={[                <Button className="editbutton cancel-btn" key="cancel" onClick={() => setIsCreditInfoModalVisible(false)}>
                    取消
                </Button>,
                <Button className="editbutton submit-button" key="submit" type="primary" onClick={handleCreditInfoSave}>
                    儲存
                </Button>,
            ]}
        >
            {editingCreditInfo.map((info, index) => (
                <Input
                    key={index}
                    value={info}
                    onChange={(e) => handleCreditInfoChange(index, e.target.value)}
                    style={{ marginBottom: '10px' }}
                    placeholder={`Credit右邊的第${index + 1}格`}
                />
            ))}
        </Modal>
        <Modal
            title="编辑更多連結"
            open={isMLEditModalVisible}
            onOk={() => {
                const updatedData = data.map(row => {
                if (row === editingRow) {
                    return { ...row, ml: editingML };
                }
                return row;
                });
                setData(updatedData);
                setIsMLEditModalVisible(false);
            }}
            onCancel={() => setIsMLEditModalVisible(false)}
            footer={[
                <Button className='editbutton' key="back" onClick={() => setIsMLEditModalVisible(false)}>取消</Button>,
                <Button className='editbutton submit-button' key="submit" type="primary" onClick={handleSave}>儲存</Button>,
              ]}
            >
            {[...Array(10)].map((_, index) => {
                const mlKey = `ml${index + 1}`;
                return (
                <Input
                    key={mlKey}
                    id={mlKey}
                    addonBefore={`圖片${index + 1}`}
                    value={editingML[mlKey] || ''}
                    onChange={e => setEditingML({...editingML, [mlKey]: e.target.value})}
                    style={{ marginBottom: '10px' }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleSave();
                      }else if (e.key === 'Escape') {
                        setIsMLEditModalVisible(false);
                      }else if (e.key === 'Tab') {
                        e.preventDefault();
                        const nextMLKey = `ml${index + 2}`;
                        const nextMLInput = document.getElementById(nextMLKey);
                        const maxMLKey = `ml${11}`;
                        const firstMLInput = document.getElementById('ml1');
                        if (nextMLInput) {
                          nextMLInput.focus();
                        }else if(nextMLKey === maxMLKey){
                          firstMLInput.focus();
                        }
                      }
                    }}
                />
                );
            })}
        </Modal>

        </>
      );
    };


export default Newwork;


