// CustomDirective.js
import React, { useEffect, useRef, useCallback, useMemo } from 'react';

const DISTANCE = 100;
const DURATION = 500;

function isElementTopInViewport(el) {
  const rect = el.getBoundingClientRect();
  return rect.top >= 0;
}

export default function CustomDirective({ children }) {
  const elementRef = useRef(null);
  const animationRef = useRef(null);
  const observerRef = useRef(null);

  const handleIntersection = useCallback(
    (entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting && isElementTopInViewport(entry.target)) {
          const animation = animationRef.current;
          animation.play();
        } else {
          const animation = animationRef.current;
          animation.cancel();
        }
      }
    },
    []
  );

  useEffect(() => {
    const el = elementRef.current;

    if (!el) {
      return;
    }

    const animation = el.animate(
      [
        {
          transform: `translateY(${DISTANCE}px)`,
          opacity: 1.5,
        },
        {
          transform: 'translateY(0)',
          opacity: 1,
        },
      ],
      {
        duration: DURATION,
        easing: 'ease-out',
        fill: 'both',
      }
    );

    animation.pause();

    animationRef.current = animation;
    observerRef.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.115, // 调整触发动画的阈值
    });

    observerRef.current.observe(el);

    return () => {
      observerRef.current.unobserve(el);
    };
  }, [handleIntersection]);

  const animatedElement = useMemo(() => <div ref={elementRef}>{children}</div>, [children]);

  return animatedElement;
}