import NavigationBar from '../nav/NavBar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import './admin.css';
import EditableTable from '../admin-all/EditableTable';
import Newwork from '../newwork/Newwork';
import { Button } from 'antd';
import ClientInfoDM from '../infoDM/ClientInfoDM/ClientInfoDM';
import ExperienceInfoDM from '../infoDM/ExperienceInfoDM/ExperienceInfoDM'
import ExhibitionInfoDM from '../infoDM/ExhibitionInfoDM/ExhibitionInfoDM'
import AwardsInfoDM from '../infoDM/AwardsInfoDM/AwardsInfoDM'
import InfoDM from '../infoDM/InfoDM/InfoDM'

function Admin() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [activeComponent, setActiveComponent] = useState('table');
  useEffect(() => {
    const validateCookie = async () => {
      try {
        const response = await fetch('/api/auth/validateToken', {
          method: 'POST',
          credentials: 'include'  // 确保发送 Cookie
        });
        const data = await response.json();
        if (response.ok) {
          setUsername(data.username);
        } else {
          console.error('Validation error:', data);
          navigate('/login');
        }
      } catch (error) {
        console.error('Validation error:', error);
        navigate('/login');
      }
    };
  
    validateCookie();
  }, [navigate]);
  

  const handleLogout = () => {
    Cookies.remove('yinz');
    navigate('/');
  };

    return (
      <main className='admin-main'>
        <NavigationBar />
        <div className='admin-container'>
          <div className='admin-left'>
            <div className='left80'>
              <div className='admin-title'>
                <h1>{username}，你好</h1>
                <h2>歡迎檢視管理系统</h2>
              </div>
              <div className='admin-buttons'>
                  <Button className='adminbtn adminbtn-table' type="primary" onClick={() => setActiveComponent('table')}>管理作品</Button>
                  <Button className='adminbtn adminbtn-new' type="primary" onClick={() => setActiveComponent('new')}>新增作品</Button>
                  <Button className='adminbtn adminbtn-new' type="primary" onClick={() => setActiveComponent('Client')}>Client</Button>
                  <Button className='adminbtn adminbtn-new' type="primary" onClick={() => setActiveComponent('Experience')}>Experience</Button>
                  <Button className='adminbtn adminbtn-new' type="primary" onClick={() => setActiveComponent('ExhibitionInfoDM')}>Exhibition</Button>
                  <Button className='adminbtn adminbtn-new' type="primary" onClick={() => setActiveComponent('AwardsInfoDM')}>Awards</Button>
                  <Button className='adminbtn adminbtn-new' type="primary" onClick={() => setActiveComponent('InfoDM')}>Info</Button>
                  <Button className='adminbtn adminbtn-logout' type="primary" onClick={handleLogout}>登出</Button>
                  
              </div>
            </div>
          </div>
          <div className='admin-content'>
            {activeComponent === 'table' && <EditableTable />}
            {activeComponent === 'new' && <Newwork />}
            {activeComponent === 'Client' && <ClientInfoDM />}
            {activeComponent === 'Experience' && <ExperienceInfoDM />}
            {activeComponent === 'ExhibitionInfoDM' && <ExhibitionInfoDM />}
            {activeComponent === 'AwardsInfoDM' && <AwardsInfoDM />}
            {activeComponent === 'InfoDM' && <InfoDM />}
            
          </div>
        </div>
      </main>
    );
}

export default Admin;