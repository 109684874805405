import React, { useEffect, useState,useRef } from 'react';
import './landingpage.css';
import NavigationBar from '../nav/NavBar';
import CopyrightNotice from '../corpyrignt/CopyrightNotice';

const Landingpage = () => {
    const [land, setLands] = useState([]);

    useEffect(() => {
        fetch('/api/ldbg')
            .then(response => {
                if(response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(data => {
                setLands(data)})
            .catch(error => console.error('Error fetching data: ', error));
    }, []);

    const movableDivRef = useRef(null);

    useEffect(() => {
      const moveDiv = (event) => {
        const x = event.clientX;
        const y = event.clientY;
    
        if (movableDivRef.current) {
          movableDivRef.current.style.left = `${x}px`;
          movableDivRef.current.style.top = `${y}px`;
        }
      };
    
      document.addEventListener('pointermove', moveDiv);
    
      return () => {
        document.removeEventListener('pointermove', moveDiv);
      };
    }, []);
    
    
    return (
        <main className='ldbg-main'>
            <div className='ldbg-container'>
                <NavigationBar />
                <CopyrightNotice/>
                <div className="ldbg-main-container">
                    {land.map((land, index) => (
                        <div className='ld-img' key={index}>
                            <div className="pixelate-image">
                            <svg className='pixelate-svg' style={{ position: 'fixed' }}>
                                    <defs>
                                <filter id="pixelate-mosaic" x="0%" y="0%" width="100%" height="100%">
                                        <feGaussianBlur stdDeviation="2" in="SourceGraphic" result="smoothed" />
                                        <feImage width="15" height="15" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAIAAAACDbGyAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWSURBVAgdY1ywgOEDAwKxgJhIgFQ+AP/vCNK2s+8LAAAAAElFTkSuQmCC" result="displacement-map" />
                                        <feTile in="displacement-map" result="pixelate-map" />
                                        <feDisplacementMap in="smoothed" in2="pixelate-map" xChannelSelector="R" yChannelSelector="G" scale="50" result="pre-final" />
                                        <feComposite operator="in" in2="SourceGraphic" />
                                    </filter>
                                    </defs>
                                    <filter id="pixelate" x="0" y="0">
                                        <feFlood x="4" y="4" height="2" width="2" />

                                        <feComposite width="10" height="10" />

                                        <feTile result="a" />

                                        <feComposite in="SourceGraphic" in2="a" operator="in" />

                                        <feMorphology operator="dilate" radius="5" />
                                    </filter>
                                </svg>
                                <div id="movableDiv" className="filter" ref={movableDivRef}></div>
                                <img src={land.ldlink} alt='img' className="pixelated-image" />
                                </div>
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
};

export default Landingpage;
