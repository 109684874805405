import React, { useEffect, useState } from 'react';
import { Table, Modal, Input, Button, message } from 'antd';
import axios from 'axios';
import './ClientInfoDM.css';

const ClientInfoDM = () => {
  const [data, setData] = useState([]);
  const [editingClientInfo, setEditingClientInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [originalSn, setOriginalSn] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/client', {
        withCredentials: true
      });
      const sortedData = response.data.sort((a, b) => a.sn - b.sn);
      setData(sortedData);
    } catch (error) {
      message.error('無法從後端加載數據');
    }
  };

  const showModal = (clientInfo) => {
    if (clientInfo) {
      setEditingClientInfo(clientInfo);
      setOriginalSn(clientInfo.sn); // 设置原始的 SN
      setIsEditing(true);
    } else {
      setEditingClientInfo({ client_en: '', client_zh: '' });
      setOriginalSn(null); // 重置 originalSn
      setIsEditing(false);
    }
    setIsModalVisible(true);
  };

  const checkSnExists = async (sn) => {
    if (!/^\d+$/.test(sn)) {
      message.error('SN 必須為純數字');
      return false;
    }
    const response = await axios.get(`/api/client/check-sn?sn=${sn}`, {
      withCredentials: true,
    });
    return response.data; // 返回 true 或 false 表示 SN 是否存在
  };
  
  const handleOk = async () => {
    try {
      let clientInfoToSave = { ...editingClientInfo };
  
      // 在保存之前，先检查 SN 的唯一性
      const snExists = await checkSnExists(clientInfoToSave.sn);
      if (!snExists && clientInfoToSave.sn !== originalSn) {
        message.error('SN 與現有數據重複，請修改 SN');
        return; // 如果 SN 已存在，并且不是当前正在编辑的原始 SN，则阻止保存
      }
      if (isEditing) {
        await axios.put(`/api/client/${originalSn}`, clientInfoToSave, {
          withCredentials: true,
        });
      } else {
        await axios.post('/api/client/create', clientInfoToSave, {
          withCredentials: true,
        });
      }

      fetchData();
      message.success(isEditing ? 'ClientInfo 更新成功' : 'ClientInfo 保存成功');
    } catch (error) {
      console.log(error);
      message.error(isEditing ? 'ClientInfo 更新失败，请稍后重试' : 'ClientInfo 保存失败，请稍后重试');
    }
    setIsModalVisible(false);
    setIsEditing(false);
  };

  //刪除路由
  const handleDelete = async (sn) => {
    try {
      await axios.delete(`/api/client/${sn}`, {
        withCredentials: true
      });
      fetchData();
      message.success('ClientInfo刪除成功');
    } catch (error) {
      message.error('ClientInfo刪除失敗，請稍後重試');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e, key) => {
    setEditingClientInfo({ ...editingClientInfo, [key]: e.target.value });
  };

  const modalFooter = (
    <div>
      <Button key="back" onClick={handleCancel} className='clientinfobtn'>
        取消
      </Button>
      <Button key="submit" type="primary" onClick={handleOk} className='clientinfobtn submit-btn'>
        確定
      </Button>
    </div>
  );

  const columns = [
    {
      title: '編號',
      dataIndex: 'sn',
      key: 'sn',
      width: "10%",
    },
    {
      title: 'client 英文',
      dataIndex: 'client_en',
      key: 'client_en',
      width: "45%",
    },
    {
      title: 'client 中文',
      dataIndex: 'client_zh',
      key: 'client_zh',
      width: "45%",
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (_, record) => (
        <div className='btngroup'>
          <Button className='clientinfodmcbtn' onClick={() => showModal(record)}>編輯</Button>
          <Button className='clientinfodmcbtn delete-button' onClick={() => handleDelete(record.sn)}>刪除</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" className='clientinfodmbtn clientinfomainbtn addworks-addbtn' onClick={() => showModal()}>新增ClientInfo</Button>
      <Table
        className='clientinfodmtable'
        dataSource={data}
        columns={columns}
        rowKey="sn"
        pagination={false}
        sortBy="sn"
      />
      <Modal
        title="編輯ClientInfo"
        open={isModalVisible}
        footer={modalFooter}
        onCancel={handleCancel}
      >
        <Input className='textbox' value={editingClientInfo.sn} onChange={(e) => handleInputChange(e, 'sn')} placeholder="編號" />
        <Input className='textbox' value={editingClientInfo.client_en} onChange={(e) => handleInputChange(e, 'client_en')} placeholder="client 英文" />
        <Input className='textbox' value={editingClientInfo.client_zh} onChange={(e) => handleInputChange(e, 'client_zh')} placeholder="client 中文" />
      </Modal>
    </>
  );
};

export default ClientInfoDM;