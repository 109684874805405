import React, { useEffect, useState } from 'react';
import { Table, Modal, Input, Button, message } from 'antd';
import axios from 'axios';
import './InfoDM.css';
import TextArea from 'antd/es/input/TextArea';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

const InfoDM = () => {
  const [data, setData] = useState([]);
  const [editingInfo, setEditingInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [originalSn, setOriginalSn] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/info', {
        withCredentials: true
      });
      const sortedData = response.data.sort((a, b) => a.sn - b.sn);
      setData(sortedData);
    } catch (error) {
      message.error('無法從後端加載數據');
    }
  };

  const showModal = (info) => {
    if (info) {
      setEditingInfo(info);
      setOriginalSn(info.sn); // 设置原始的 SN
      setIsEditing(true);
    } else {
      setEditingInfo({ info_en: '', info_zh: '' });
      setOriginalSn(null); // 重置 originalSn
      setIsEditing(false);
    }
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      let infoToSave = { ...editingInfo };

      if (!infoToSave.sn) {
        // 如果没有 SN，则生成一个新的 SN
        const existingSns = data.map((item) => item.sn);
        let newSn = 1;
        while (existingSns.includes(newSn)) {
          newSn++;
        }
        infoToSave.sn = newSn;
      } else {
        // 如果有 SN，则检查是否与现有的 SN 重复
        const existingSns = data.filter((item) => item.sn !== editingInfo.sn).map((item) => item.sn);
        if (existingSns.includes(infoToSave.sn)) {
          message.error('SN 重複，請修改 SN');
          return;
        }if (!/^\d+$/.test(infoToSave.sn)) {
          message.error('SN 必須為純數字');
          return false;
        }
      }

      if (isEditing) {
        await axios.put(`/api/info/${originalSn}`, infoToSave, {
          withCredentials: true,
        });
      } else {
        await axios.post('/api/info/create', infoToSave, {
          withCredentials: true,
        });
      }

      fetchData();
      message.success(isEditing ? 'Info 更新成功' : 'Info 保存成功');
    } catch (error) {
      console.log(error);
      message.error(isEditing ? 'Info 更新失败，请稍后重试' : 'Info 保存失败，请稍后重试');
    }
    setIsModalVisible(false);
    setIsEditing(false);
  };

  //刪除路由
  const handleDelete = async (sn) => {
    try {
      await axios.delete(`/api/info/${sn}`, {
        withCredentials: true
      });
      fetchData();
      message.success('Info刪除成功');
    } catch (error) {
      message.error('Info刪除失敗，請稍後重試');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e, key) => {
    setEditingInfo({ ...editingInfo, [key]: e.target.value });
  };

  const modalFooter = (
    <div>
      <Button key="back" onClick={handleCancel} className='infobtn'>
        取消
      </Button>
      <Button key="submit" type="primary" onClick={handleOk} className='infobtn submit-btn'>
        確定
      </Button>
    </div>
  );

  const columns = [
    {
      title: '編號',
      dataIndex: 'sn',
      key: 'sn',
      width: "4%",
    },
    {
      title: 'info 英文',
      dataIndex: 'info_en',
      key: 'info_en',
      width: "45%",
    },
    {
      title: 'info 中文',
      dataIndex: 'info_zh',
      key: 'info_zh',
      width: "45%",
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: "6%",
      render: (_, record) => (
        <div className='btngroup'>
          <Button className='infodmcbtn' onClick={() => showModal(record)}>編輯</Button>
          <Button className='infodmcbtn delete-button' onClick={() => handleDelete(record.sn)}>刪除</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" className='infodmbtn infomainbtn addworks-addbtn' onClick={() => showModal()}>新增Info</Button>
      <Table
        className='infodmtable'
        dataSource={data}
        columns={columns}
        rowKey="sn"
        pagination={false}
        sortBy="sn"
      />
      <Modal
        title="編輯Info"
        open={isModalVisible}
        footer={modalFooter}
        onCancel={handleCancel}
      >
        <Input className='textbox' value={editingInfo.sn} onChange={(e) => handleInputChange(e, 'sn')} placeholder="編號" />
        <TextArea rows={8} className='textbox' value={editingInfo.info_en} onChange={(e) => handleInputChange(e, 'info_en')} placeholder="info 英文" />
        <TextArea rows={8}  className='textbox' value={editingInfo.info_zh} onChange={(e) => handleInputChange(e, 'info_zh')} placeholder="info 中文" />
      </Modal>
    </>
  );
};

export default InfoDM;